import React from "react";

import AppContext from "./Context/AppContext";
import OfflineContext from "./Context/OfflineContext";
import PlayerView from "./Components/PlayerView";

import getLogger from "js/core/logger";
import { MetricName } from "common/interfaces/IMetric";
import { useMountedAt } from "js/core/utilities/useMountedAt";

const logger = getLogger();

export enum PlayerContextType {
    APP = "app",
    OFFLINE = "offline"
}

interface PlayerProps {
    contextType: PlayerContextType;
    onExit?: (unmountTrigger: string, lastSlideIndex: number) => void;
    startSlideIndex?: number;
    openPresenterOnPlaybackStarted?: boolean;
    enterFullscreenOnPlaybackStarted?: boolean;
    presentationOrLinkId?: string;
    isPreviewFromEditor?: boolean;
    openPresenterView?: boolean;
}

const Player = React.forwardRef(function player(props: PlayerProps, ref) {
    const {
        contextType = PlayerContextType.APP,
        ...playerProps
    } = props;

    const mountedAt = useMountedAt();

    const handleFirstCanvasRendered = () => {
        logger.metric(MetricName.PLAYER_LOAD_TIME, {
            presentationId: playerProps.presentationOrLinkId,
            loadTimeMs: Date.now() - mountedAt
        });
    };

    const Context = (contextType === PlayerContextType.APP ? AppContext : OfflineContext) as any;
    return (
        <Context {...playerProps}>
            {contextData => (
                <PlayerView
                    ref={ref}
                    onFirstCanvasRendered={handleFirstCanvasRendered}
                    {...props}
                    // Note: context may override props values (e.g. presentation) so it goes after
                    {...contextData}
                />
            )}
        </Context>
    );
});

export default Player;
